import AccessAlarmsOutlinedIcon from "@mui/icons-material/AccessAlarmsOutlined";
import { Alert } from "@mui/material";
import { PRIORITY_ACCESS_COLOR } from "@src/appV2/lib/Theme/constants";
import { formatDistanceToNow, isPast, isValid, parseISO } from "date-fns";

export interface PriorityAccessAlertProps {
  priorityAccessTill: string;
}

export function PriorityAccessAlert(props: PriorityAccessAlertProps) {
  const { priorityAccessTill } = props;

  if (!isValid(parseISO(priorityAccessTill)) || isPast(parseISO(priorityAccessTill))) {
    return null;
  }

  return (
    <Alert
      variant="insight"
      icon={<AccessAlarmsOutlinedIcon fontSize="small" sx={{ color: PRIORITY_ACCESS_COLOR }} />}
      sx={{
        padding: 1,
        display: "flex",
        alignItems: "center",
        ".MuiAlert-message": {
          padding: 0,
        },
        ".MuiAlert-icon": {
          padding: 0,
        },
      }}
    >
      Priority Access will expire in{" "}
      {formatDistanceToNow(parseISO(priorityAccessTill), { includeSeconds: true })}.
    </Alert>
  );
}
