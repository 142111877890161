import { LoadingButton, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { LegacyTwoButtonDialogActions } from "@src/appV2/lib/Dialogs/LegacyDialogActions";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";

import { type ShiftBlockWithMappedDetails } from "../api/useFetchPaginatedShiftBlocks";
import { useUpdateShiftBlock } from "../api/useUpdateBlockShift";
import { SHIFT_BLOCKS_PATH } from "../paths";
import { SHIFT_BLOCK_ACTIONS } from "../utils";

export interface BookShiftBlockDialogProps {
  modalState: UseModalState;
  shifts: ShiftBlockWithMappedDetails["shifts"];
  shiftBlockId: string;
  agentId: string;
  facilityId: string;
  onConfirmBooking: () => void;
}
export function BookShiftBlockDialog(props: BookShiftBlockDialogProps) {
  const { modalState, shifts, shiftBlockId, agentId, facilityId, onConfirmBooking } = props;
  const { showErrorToast, showSuccessToast } = useToast();
  const history = useHistory();
  const { path: currentRouterUrl } = useRouteMatch();
  const { mutateAsync: mutateBookShiftBlock, isLoading: isLoadingBookShiftBlock } =
    useUpdateShiftBlock({
      shiftBlockId,
    });

  return (
    <Dialog open={modalState.modalIsOpen}>
      <DialogTitle>Book This Block</DialogTitle>

      <DialogContent>
        <Text align="center">
          You should only book this block if you are 100% committed to working all {shifts.length}{" "}
          of the shifts in this block and to arriving on time for each of these shifts.
        </Text>
      </DialogContent>
      <LegacyTwoButtonDialogActions
        leftAction={
          <Button
            fullWidth
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                action: SHIFT_BLOCK_ACTIONS.DISCARD_BLOCK_BOOKING_TAPPED,
                shiftBlockId,
                facilityId,
                totalShifts: shifts.length,
                location: currentRouterUrl,
              });
              modalState.closeModal();
            }}
          >
            Cancel
          </Button>
        }
        rightAction={
          <LoadingButton
            fullWidth
            isLoading={isLoadingBookShiftBlock}
            onClick={async () => {
              try {
                const offers = shifts
                  .filter((shift) => shift.offer)
                  .map((shift) => ({ id: shift.offer?.id ?? "", shiftId: shift.id }));
                await mutateBookShiftBlock({
                  data: {
                    type: "shift-block",
                    id: shiftBlockId,
                    attributes: {
                      agentId,
                      offers,
                    },
                  },
                });
                logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                  action: SHIFT_BLOCK_ACTIONS.CONFIRM_BOOK_BLOCK_TAPPED,
                  shiftBlockId,
                  facilityId,
                  totalShifts: shifts.length,
                  location: currentRouterUrl,
                });
                // Once the block is booked we need agentId to refetch the booked block
                history.replace(
                  generatePath(
                    `${RootPaths.APP_V2_HOME}/${SHIFT_BLOCKS_PATH}/:shiftBlockId/:agentId`,
                    {
                      shiftBlockId,
                      agentId,
                    }
                  )
                );
                showSuccessToast("Shift block booked successfully.");
                onConfirmBooking();
              } catch {
                showErrorToast("Something went wrong while booking the shift block.");
              } finally {
                modalState.closeModal();
              }
            }}
          >
            Book
          </LoadingButton>
        }
      />
    </Dialog>
  );
}
