import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { grey } from "@mui/material/colors";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { type ShiftBlockWithMappedDetails } from "../api/useFetchPaginatedShiftBlocks";
import { SHIFT_BLOCK_ACTIONS } from "../utils";
import { CancelShiftBlockDialog } from "./CancelShiftBlockDialog";

interface BookedShiftBlockActionsProps {
  shiftBlockWithDetails: ShiftBlockWithMappedDetails;
}

export function BookedShiftBlockActions(props: BookedShiftBlockActionsProps) {
  const { shiftBlockWithDetails } = props;
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();
  const cancelShiftBlockState = useModalState();
  const history = useHistory();
  const { path: currentRouterUrl } = useRouteMatch();
  if (!shiftBlockWithDetails.isShiftBlockAssigned) {
    return null;
  }

  return (
    <>
      <IconButton
        edge="end"
        size="small"
        sx={{
          paddingRight: 0,
        }}
        onClick={(event) => {
          logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
            action: SHIFT_BLOCK_ACTIONS.MORE_ACTION_TAPPED,
            shiftBlockId: shiftBlockWithDetails.id,
            facilityId: shiftBlockWithDetails.facility.id,
            location: currentRouterUrl,
          });

          setAnchorElement(event.currentTarget);
        }}
      >
        <MoreVertIcon
          sx={{
            color: grey[600],
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={isDefined(anchorElement)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => {
          setAnchorElement(undefined);
        }}
      >
        <MenuItem
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
              action: SHIFT_BLOCK_ACTIONS.CANCEL_BLOCK_TAPPED,
              shiftBlockId: shiftBlockWithDetails.id,
              facilityId: shiftBlockWithDetails.facility.id,
              location: currentRouterUrl,
            });
            cancelShiftBlockState.openModal();
          }}
        >
          Cancel block
        </MenuItem>
      </Menu>
      <CancelShiftBlockDialog
        modalState={cancelShiftBlockState}
        shiftBlockId={shiftBlockWithDetails.id}
        facilityId={shiftBlockWithDetails.facility.id}
        totalShifts={shiftBlockWithDetails.shifts.length}
        onConfirmBooking={() => {
          history.goBack();
        }}
      />
    </>
  );
}
