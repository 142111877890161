import { Text, useModalState } from "@clipboard-health/ui-react";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { type SyntheticEvent } from "react";

import { getHumanReadableShiftDuration, MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS } from "../utils";

interface MandatoryLunchInformation {
  facilityName: string;
  shiftTime: number;
}

interface LunchBreakInformationIconAndDialogProps {
  shiftDetails: MandatoryLunchInformation;
}
export function LunchBreakInformationIconAndDialog(props: LunchBreakInformationIconAndDialogProps) {
  const { shiftDetails } = props;
  const lunchBreakDialogState = useModalState();
  const isMandatoryLunchBreakInfoPopupEnabled = useCbhFlag(
    CbhFeatureFlag.ENABLE_LUNCH_BREAK_INFO_POPUP,
    {
      defaultValue: false,
    }
  );

  return (
    <>
      {isMandatoryLunchBreakInfoPopupEnabled &&
      shiftDetails.shiftTime > MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS ? (
        <IconButton
          size="small"
          edge="end"
          aria-label="Mandatory Lunch Break Button"
          sx={{
            paddingRight: 0,
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            lunchBreakDialogState.openModal();
          }}
        >
          <InfoIcon color="primary" fontSize="small" />
        </IconButton>
      ) : null}
      <Dialog
        open={lunchBreakDialogState.modalIsOpen}
        onClose={(event) => {
          (event as SyntheticEvent)?.stopPropagation?.();
          lunchBreakDialogState.closeModal();
        }}
      >
        <DialogTitle>
          <Text bold align="center">
            {shiftDetails.facilityName} requires a mandatory 30 minute break!
          </Text>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            The full duration for each shift in this block is{" "}
            {getHumanReadableShiftDuration({
              shiftDuration: shiftDetails.shiftTime,
              withLunchBreak: true,
            })}
            . Your pay for each shift will reflect that you worked{" "}
            {getHumanReadableShiftDuration({
              shiftDuration: shiftDetails.shiftTime,
              withLunchBreak: false,
            })}{" "}
            because a 30 minutes unpaid break is mandatory at this facility.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Button
            fullWidth
            onClick={(event) => {
              event.stopPropagation();
              lunchBreakDialogState.closeModal();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
