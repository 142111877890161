import { InternalLink, LoadingButton, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { Badge, Box, Button, Divider, Stack } from "@mui/material";
import { useGetChatChannels } from "@src/appV2/Facilities/Chat/api/useGetChatChannels";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useHistory, useRouteMatch } from "react-router-dom";

import { useCreateChatChannel } from "../api/useCreateChatChannel";
import { SHIFT_BLOCK_ACTIONS } from "../utils";

interface FacilityChatButtonContainerProps {
  shiftBlockId: string;
  facilityId: string;
  agentId: string;
  isShiftBlockAssigned: boolean;
}

export function FacilityChatButtonContainer(props: FacilityChatButtonContainerProps) {
  const { shiftBlockId, agentId, facilityId, isShiftBlockAssigned } = props;
  const history = useHistory();
  const { showErrorToast } = useToast();
  const { isLoading: isLoadingChatChannels, data: chatChannelsData } = useGetChatChannels();
  const { path: currentRouterUrl } = useRouteMatch();
  const { mutateAsync: createChatChannel, isLoading: isCreatingChatChannel } =
    useCreateChatChannel();

  if (isLoadingChatChannels) {
    return null;
  }

  const currentChannel = chatChannelsData?.channels?.find(
    (channel) => channel.url === `${facilityId}_${agentId}`
  );

  if (isDefined(currentChannel?.url)) {
    const unreadMessageCount = currentChannel?.unreadMessageCount ?? 0;
    return (
      <>
        <Stack spacing={1}>
          <Text>Have any questions about this block?</Text>
          <Box>
            <Badge color="error" overlap="rectangular" badgeContent={unreadMessageCount}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                component={InternalLink}
                to={`/home/account/chat/${facilityId}`}
                startIcon={<QuestionAnswerOutlinedIcon />}
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                    action: SHIFT_BLOCK_ACTIONS.FACILITY_CHAT_TAPPED,
                    shiftBlockId,
                    facilityId,
                    isShiftBlockAssigned,
                    chatChannelPresent: true,
                    location: currentRouterUrl,
                  });
                }}
              >
                Chat with facility
              </Button>
            </Badge>
          </Box>
        </Stack>
        <Divider />
      </>
    );
  }

  if (!isDefined(currentChannel?.url)) {
    return (
      <Stack spacing={1}>
        <Text>Have any questions about this block?</Text>
        <Box>
          <LoadingButton
            variant="outlined"
            color="primary"
            size="small"
            isLoading={isCreatingChatChannel}
            startIcon={<QuestionAnswerOutlinedIcon />}
            onClick={async () => {
              try {
                await createChatChannel({
                  facilityId,
                  agentId,
                });
                history.push(`/home/account/chat/${facilityId}`);
                logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                  action: SHIFT_BLOCK_ACTIONS.FACILITY_CHAT_TAPPED,
                  shiftBlockId,
                  facilityId,
                  isShiftBlockAssigned,
                  chatChannelPresent: false,
                  location: currentRouterUrl,
                });
              } catch {
                showErrorToast("Failed to create chat channel. Please try again.");
              }
            }}
          >
            Chat with facility
          </LoadingButton>
        </Box>
      </Stack>
    );
  }

  return null;
}
