import { LoadingButton, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { LegacyTwoButtonDialogActions } from "@src/appV2/lib/Dialogs/LegacyDialogActions";
import { useRouteMatch } from "react-router-dom";

import { ShiftBlockStatus, useUpdateShiftBlock } from "../api/useUpdateBlockShift";
import { SHIFT_BLOCK_ACTIONS } from "../utils";

export interface CancelShiftBlockDialogProps {
  modalState: UseModalState;
  shiftBlockId: string;
  facilityId: string;
  totalShifts: number;
  onConfirmBooking: () => void;
}
export function CancelShiftBlockDialog(props: CancelShiftBlockDialogProps) {
  const { modalState, shiftBlockId, facilityId, totalShifts, onConfirmBooking } = props;
  const { showErrorToast, showInfoToast } = useToast();
  const { path: currentRouterUrl } = useRouteMatch();
  const { mutateAsync: mutateBookShiftBlock, isLoading: isLoadingBookShiftBlock } =
    useUpdateShiftBlock({
      shiftBlockId,
    });

  return (
    <Dialog open={modalState.modalIsOpen}>
      <DialogTitle>Cancel This Block</DialogTitle>

      <DialogContent>
        <Text align="center">
          This cancellation will remove you from all future shifts in this block and penalize your
          Attendance Score. Are you sure you want to cancel this block?
        </Text>
      </DialogContent>
      <LegacyTwoButtonDialogActions
        leftAction={
          <Button
            fullWidth
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                action: SHIFT_BLOCK_ACTIONS.KEEP_BLOCK_TAPPED,
                shiftBlockId,
                facilityId,
                totalShifts,
                location: currentRouterUrl,
              });
              modalState.closeModal();
            }}
          >
            Keep block
          </Button>
        }
        rightAction={
          <LoadingButton
            fullWidth
            isLoading={isLoadingBookShiftBlock}
            sx={{
              color: (theme) => theme.palette.error.light,
            }}
            onClick={async () => {
              try {
                await mutateBookShiftBlock({
                  data: {
                    type: "shift-block",
                    id: shiftBlockId,
                    attributes: {
                      status: ShiftBlockStatus.CANCELLED,
                    },
                  },
                });
                logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                  action: SHIFT_BLOCK_ACTIONS.YES_CANCEL_TAPPED,
                  shiftBlockId,
                  facilityId,
                  totalShifts,
                  location: currentRouterUrl,
                });
                showInfoToast("Shift block canceled successfully.");
                onConfirmBooking();
              } catch {
                showErrorToast("Something went wrong while canceling the shift block.");
              } finally {
                modalState.closeModal();
              }
            }}
          >
            Yes, cancel
          </LoadingButton>
        }
      />
    </Dialog>
  );
}
