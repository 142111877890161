import { Li, Span, Text, Ul, type UseModalState } from "@clipboard-health/ui-react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { STATE_LICENSE_REQUIREMENT_ID } from "@src/constants";
import pluralize from "pluralize";

import { SHIFT_BLOCK_ACTIONS } from "../utils";

export interface MissingDocuments {
  reqId: string;
  name: string;
  visibleToHCP: boolean;
}

interface MissingDocumentsItemProps {
  modalState: UseModalState;
  onConfirm: () => void;
  missingDocuments: Set<MissingDocuments>;
  analyticsProps: {
    shiftBlockId: string;
    facilityId: string;
    location: string;
  };
}
export function MissingDocumentsDialog(props: MissingDocumentsItemProps) {
  const { modalState, onConfirm, missingDocuments, analyticsProps } = props;

  const visibleMissingDocuments: MissingDocuments[] = [];
  const invisibleMissingDocuments: MissingDocuments[] = [];

  missingDocuments.forEach((requirement) => {
    // Copied from https://github.com/ClipboardHealth/cbh-mobile-app/blob/main/src/app/dayView/Alerts/MissingDocumentsAlert.tsx#L38
    if (requirement.reqId === STATE_LICENSE_REQUIREMENT_ID) {
      visibleMissingDocuments.push({
        ...requirement,
        name: "State License (upload as Miscellaneous)",
      });
    } else if (requirement.visibleToHCP) {
      visibleMissingDocuments.push(requirement);
    } else {
      invisibleMissingDocuments.push(requirement);
    }
  });
  const hasMoreDocuments = missingDocuments.size > 6;

  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={() => {
        modalState.closeModal();
        logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
          action: SHIFT_BLOCK_ACTIONS.CLOSE_MISSING_DOCUMENTS_POPUP_TAPPED,
          missingDocuments,
          ...analyticsProps,
        });
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <AddCircleIcon color="primary" fontSize="large" />
        <br />
        You&apos;re Missing{" "}
        <Span color={(theme) => theme.palette.primary.main}>{missingDocuments.size}</Span>{" "}
        {pluralize("Document", missingDocuments.size)}
      </DialogTitle>

      <DialogContent>
        {visibleMissingDocuments.length > 0 && (
          <>
            <Text align="center">
              {`To complete your booking, the following ${pluralize(
                "document",
                missingDocuments.size
              )} must be uploaded and approved:`}
            </Text>
            <br />
            <Ul>
              {visibleMissingDocuments.slice(0, 6).map((document) => (
                <Li key={document.reqId}>
                  <Text bold>{document.name}</Text>
                </Li>
              ))}
            </Ul>
          </>
        )}
        {invisibleMissingDocuments.length > 0 && visibleMissingDocuments.length < 6 && (
          <>
            <Text align="center">
              We will process the following requirements for you. Please contact
              onboarding@clipboardhealth.com if you have any questions:{" "}
            </Text>
            <br />
            <Ul>
              {invisibleMissingDocuments
                .slice(0, 6 - visibleMissingDocuments.length)
                .map((document) => (
                  <Li key={document.reqId}>
                    <Text bold>{document.name}</Text>
                  </Li>
                ))}
            </Ul>
          </>
        )}
        {hasMoreDocuments && (
          <Text align="center">
            Click to upload your documents and view the remaining missing requirements.
          </Text>
        )}
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={1} justifyContent="end">
          <Button
            onClick={() => {
              modalState.closeModal();
              logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                action: SHIFT_BLOCK_ACTIONS.CLOSE_MISSING_DOCUMENTS_POPUP_TAPPED,
                missingDocuments,
                ...analyticsProps,
              });
            }}
          >
            Close
          </Button>
          {visibleMissingDocuments.length > 0 && (
            <Button
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_BLOCKS, {
                  action: SHIFT_BLOCK_ACTIONS.PROCEED_TO_MISSING_DOCUMENTS_TAPPED,
                  missingDocuments,
                  ...analyticsProps,
                });
                onConfirm();
              }}
            >
              Upload Docs
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
