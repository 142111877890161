import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { grey } from "@mui/material/colors";

export function EmptyShiftBlocksFacilityView() {
  return (
    <Stack spacing={0.5} alignItems="left" justifyContent="left" flex={1}>
      <Text bold variant="h3" color={grey[600]}>
        No blocks of shifts at this moment
      </Text>
      <Text variant="body2">
        As soon as this facility posts a block of shifts, it will be listed here.
      </Text>
    </Stack>
  );
}
