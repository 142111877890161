import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

export function EmptyShiftBlock() {
  return (
    <Stack spacing={1} alignItems="center" justifyContent="center" flex={1}>
      <Text variant="h2">No blocks at this moment</Text>
      <Text variant="body2" align="center">
        As soon as a block of shifts is available, it will be listed here.
      </Text>
      <ExternalLink
        to="https://support.clipboardhealth.com/hc/en-us/articles/21777980781207"
        target="_blank"
      >
        What is a block of shifts?
      </ExternalLink>
      <Text variant="body2" align="center">
        You can search for single shifts by switching to the &quot;Per Diem&quot; tab at the top of
        this page.
      </Text>
    </Stack>
  );
}
